<section class="flex-container">
  <app-password-generator-form
    class="app-section"
    (passwordGenerate)="onPasswordGenerate($event)">
  </app-password-generator-form>
  
  <app-passwords
    class="app-section"
    *ngIf="passwords.length"
    [passwords]="passwords"
    [hideAllPasswords]="hideAllPasswords"
    (hideAllPasswordsClick)="hideAllPasswords = !hideAllPasswords"
    (copyToClipboard)="copyPasswordToClipboard($event)">
  </app-passwords>
</section>