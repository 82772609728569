import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordGeneratorService } from 'src/app/services/password-generator.service';
import { PasswordConfiguration } from './../../models/password-configuration';

@Component({
  selector: 'app-password-generator-container',
  templateUrl: './password-generator-container.component.html',
  styleUrls: ['./password-generator-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordGeneratorContainerComponent {
  passwords: string[] = [];
  hideAllPasswords = false;

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private passwordService: PasswordGeneratorService,
  ) {
    for (let index = 0; index < 4; index++) {
      const password = passwordService.generate({
        passwordLength: 32,
        numberOfPasswords: 4,
        includeLowercaseChars: true,
        includeUppercaseChars: true,
        includeNumbers: true,
        includeSymbols: false
      });

      this.passwords.push(password);
    }
  }

  onPasswordGenerate(passwordConfig: PasswordConfiguration): void {
    const newPasswords = [];

    for (let index = 0; index < passwordConfig.numberOfPasswords; index++) {
      newPasswords.push(this.passwordService.generate(passwordConfig));
    }

    this.passwords = newPasswords;
    this.hideAllPasswords = false;
  }

  copyPasswordToClipboard(element: any): void {
    this.clipboard.copy(element.value);

    if (!this.isMobileDevice()) {
      this.snackBar.open('Password copied to clipboard!', 'Close', { duration: 3000 });
    }
  }

  private isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
