<form class="mat-elevation-z8 password-options-form" [formGroup]="form" (ngSubmit)="generatePasswords()">
  <mat-toolbar color="primary">
    <span class="title">Password Options</span>

    <button mat-raised-button color="accent" type="submit"
      matTooltip="All passwords are generated locally on your device.">
      Generate &nbsp;
      <mat-icon matSuffix>https</mat-icon>
    </button>
  </mat-toolbar>

  <mat-card appearance="outlined" class="card">
    <mat-card-content class="content">
      <section class="input-section">
        <!-- TODO: use regex to ensure no dashes etc are used -->
        <mat-form-field appearance="outline">
          <mat-label>Password Length</mat-label>

          <input matInput appNumericOnly formControlName="passwordLength" autocomplete="off" type="number" min="6" max="2048">
          <mat-error *ngIf="form.get('passwordLength').hasError('required')">
            Password length is required.
          </mat-error>

          <mat-error *ngIf="form.get('passwordLength').hasError('pattern')">
            Password length must be a valid number.
          </mat-error>

          <mat-error *ngIf="form.get('passwordLength').hasError('min')">
            Password must be at least 6 characters.
          </mat-error>

          <mat-error *ngIf="form.get('passwordLength').hasError('max')">
            Password cannot exceed 2048 characters.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Number of Passwords</mat-label>

          <input matInput appNumericOnly formControlName="numberOfPasswords" autocomplete="off" type="number" min="1" max="10">

          <mat-error *ngIf="form.get('numberOfPasswords').hasError('required')">
            Number of passwords is required.
          </mat-error>

          <mat-error *ngIf="form.get('numberOfPasswords').hasError('min')">
            At least 1 password must be generated.
          </mat-error>

          <mat-error *ngIf="form.get('numberOfPasswords').hasError('max')">
            A maximum of 10 passwords can be generated at one time.
          </mat-error>
        </mat-form-field>
      </section>

      <section class="checkbox-section" formGroupName="passwordAlphabet">
        <mat-checkbox (change)="onAlphabetChange($event, 'includeSymbols')" formControlName="includeSymbols">
          Symbols
        </mat-checkbox>

        <mat-checkbox (change)="onAlphabetChange($event, 'includeNumbers')" formControlName="includeNumbers">
          Numbers
        </mat-checkbox>

        <mat-checkbox (change)="onAlphabetChange($event, 'includeLowercaseChars')" formControlName="includeLowercaseChars">
          Lowercase Characters
        </mat-checkbox>

        <mat-checkbox (change)="onAlphabetChange($event, 'includeUppercaseChars')" formControlName="includeUppercaseChars">
          Uppercase Characters
        </mat-checkbox>
      </section>
    </mat-card-content>
  </mat-card>
</form>