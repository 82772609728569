<div class="mat-elevation-z8">
  <mat-toolbar color="primary">
    <span class="title">Passwords</span>
    
    <button
      (click)="hideAllPasswordsClick.emit()"
      mat-raised-button color="accent"
      type="button"
    >
      {{ hideAllPasswords ? 'Show' : 'Hide' }} All &nbsp;
      <mat-icon matSuffix>remove_red_eye</mat-icon>
    </button>
  </mat-toolbar>
  
  <mat-card appearance="outlined">
    <mat-card-content [formGroup]="form">
      <div
        class="password-row"
        formArrayName="passwords"
        *ngFor="let password of passwordsArray.controls; let i = index"
      >
        <mat-form-field class="password-field" appearance="outline">
          <mat-label>Password {{ i + 1 }}</mat-label>

          <input #passwordRef 
            [formControlName]="i" 
            matInput
            [type]="hideAllPasswords ? 'password' : 'text'"
            autocomplete="off"
          >

          <mat-icon
            matSuffix
            class="copy-icon"
            matTooltip="Copy password"
            (click)="copyPasswordToClipboard($event, passwordRef)"
          >
            content_copy
          </mat-icon>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>