import { Injectable } from '@angular/core';
import { PasswordConfiguration } from '../models/password-configuration';

const LOWERCASE_CHARS = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const NUMBERS = '0123456789';
const SYMBOLS = `!"£$%^&*()_-+=|\\<>,./;:{}[]~#?`;

@Injectable({
  providedIn: 'root'
})
export class PasswordGeneratorService {
  generate(configuration: PasswordConfiguration): string {
    const {
      includeNumbers,
      includeSymbols,
      includeLowercaseChars,
      includeUppercaseChars,
      passwordLength,
    } = configuration;

    let password = '';
    const usedAlphabets = [];

    if (includeNumbers) {
      password += PasswordGeneratorService.selectRandomCharFromAlphabet(NUMBERS);
      usedAlphabets.push(NUMBERS);
    }

    if (includeLowercaseChars) {
      password += PasswordGeneratorService.selectRandomCharFromAlphabet(LOWERCASE_CHARS);
      usedAlphabets.push(LOWERCASE_CHARS);
    }

    if (includeUppercaseChars) {
      password += PasswordGeneratorService.selectRandomCharFromAlphabet(UPPERCASE_CHARS);
      usedAlphabets.push(UPPERCASE_CHARS);
    }

    if (includeSymbols) {
      password += PasswordGeneratorService.selectRandomCharFromAlphabet(SYMBOLS);
      usedAlphabets.push(SYMBOLS);
    }

    const allChars = usedAlphabets.join('');
    const remainingLength = passwordLength - password.length;

    for (let index = 0; index < remainingLength; index++) {
      password += PasswordGeneratorService.selectRandomCharFromAlphabet(allChars);
    }

    return PasswordGeneratorService.shufflePassword(password);
  }

  private static selectRandomCharFromAlphabet(alphabet: string) {
    const randomByte = new Uint8Array(1);
    window.crypto.getRandomValues(randomByte);
    const index = randomByte[0] % alphabet.length;
    return alphabet[index];
  }

  private static shufflePassword(password: string): string {
    const array = password.split('');
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join('');
  }
}