import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PasswordGeneratorFormComponent } from './components/password-generator-form/password-generator-form.component';
import { PasswordsComponent } from './components/passwords/passwords.component';
import { PasswordGeneratorContainerComponent } from './containers/password-generator-container/password-generator-container.component';
import { MaterialModule } from './material.module';
import { NumericOnlyDirective } from './directives/numeric-only.directive';

@NgModule({
  declarations: [
    AppComponent,
    PasswordGeneratorFormComponent,
    PasswordsComponent,
    PasswordGeneratorContainerComponent,
    NotFoundComponent,
    AboutComponent,
    NumericOnlyDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
