import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-passwords',
  templateUrl: './passwords.component.html',
  styleUrls: ['./passwords.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordsComponent implements OnChanges {
  @Input() passwords: string[] = [];
  @Input() hideAllPasswords: boolean;
  @Output() hideAllPasswordsClick = new EventEmitter<void>();
  @Output() copyToClipboard = new EventEmitter<ElementRef>();

  form: FormGroup;

  get passwordsArray(): FormArray {
    return this.form.get('passwords') as FormArray;
  }

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      passwords: this.formBuilder.array([this.buildPasswordRow()])
    });
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.buildPasswordRows(this.passwords || []);
  }

  public copyPasswordToClipboard(event: Event, passwordRef: ElementRef): void {
    event.preventDefault();
    event.stopPropagation();
    this.copyToClipboard.emit(passwordRef);
    passwordRef.nativeElement.blur();
  }

  private buildPasswordRows(passwords: string[] = []): void {
    this.passwordsArray.controls = [];
    for (const password of passwords) {
      this.passwordsArray.push(this.buildPasswordRow(password));
    }
  }

  private buildPasswordRow(value: string = ''): AbstractControl {
    return this.formBuilder.control(value);
  }
}
