<mat-card appearance="outlined" class="content">
  <mat-card-header>
    <mat-card-title>PassGen</mat-card-title>
    <mat-card-subtitle>
      A free and secure password generation tool
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <p>
      PassGen is a secure password generator developed and maintained by Software Developer
      Tom Phillips. It is completely free to use and can be useful for coming up with robust
      passwords.
    </p>
  </mat-card-content>
</mat-card>
