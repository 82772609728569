<div class="flex-container">
  <div class="app-section">
    <mat-toolbar color="primary">
      <span class="title">404 Not Found</span>
      <button mat-raised-button routerLink="/" color="accent" type="button">
        Return Home
        <mat-icon matSuffix>home</mat-icon>
      </button>
    </mat-toolbar>

    <mat-card appearance="outlined" class="card">
      <h1>The URL you requested does not exist</h1>
      <mat-icon class="sad-icon">sentiment_dissatisfied</mat-icon>
    </mat-card>
  </div>
</div>