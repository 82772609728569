import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { PasswordConfiguration } from 'src/app/models/password-configuration';

type PasswordAlphabetFormGroup = {
  includeSymbols: FormControl<boolean>;
  includeNumbers: FormControl<boolean>;
  includeLowercaseChars: FormControl<boolean>;
  includeUppercaseChars: FormControl<boolean>;
}

type PasswordOptionsFormGroup = {
  passwordLength: FormControl<number>;
  numberOfPasswords: FormControl<number>;
  passwordAlphabet: FormGroup<PasswordAlphabetFormGroup>;
}

@Component({
  selector: 'app-password-generator-form',
  templateUrl: './password-generator-form.component.html',
  styleUrls: ['./password-generator-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordGeneratorFormComponent implements OnDestroy {
  private ngUnsubscribe$ = new Subject();

  @Output() passwordGenerate = new EventEmitter<PasswordConfiguration>();
  @Output() numPasswordChange = new EventEmitter<number>();

  form: FormGroup<PasswordOptionsFormGroup> = this.formBuilder.group({
    passwordLength: [32, [
      Validators.required,
      Validators.min(6),
      Validators.max(2048)
    ]],
    numberOfPasswords: [4, [
      Validators.required,
      Validators.min(1),
      Validators.max(10)
    ]],
    passwordAlphabet: this.formBuilder.group({
      includeSymbols: [false],
      includeNumbers: [true],
      includeLowercaseChars: [true],
      includeUppercaseChars: [true],
    })
  });

  get numPasswordsCtrl(): AbstractControl {
    return this.form.get('numberOfPasswords');
  }

  get passwordAlphabetGroup(): FormGroup<PasswordAlphabetFormGroup> {
    return this.form.get('passwordAlphabet') as FormGroup<PasswordAlphabetFormGroup>;
  }

  constructor(private formBuilder: NonNullableFormBuilder) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  generatePasswords(): void {
    if (this.form.invalid) {
      return;
    }

    const passwordConfig = {
      ...this.form.value,
      ...this.form.value.passwordAlphabet
    } as PasswordConfiguration;

    this.passwordGenerate.emit(passwordConfig);
  }

  onAlphabetChange(_event: MatCheckboxChange, controlName: string): void {
    const control = this.passwordAlphabetGroup.get(controlName);

    if (this.wasLastSelectedAlphabet()) {
      control.setValue(true, { emitEvent: false });
    }
  }

  wasLastSelectedAlphabet(): boolean {
    return Object.values(this.passwordAlphabetGroup.controls)
      .every((control: FormControl) => control.value === false);
  }
}
