import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // numeric keys, control keys, navigation keys, etc.
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];

    if ((event.key < '0' || event.key > '9') && !allowedKeys.includes(event.key) && event.key !== 'Delete') {
      event.preventDefault();
    }
  }
}
